exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-brewguide-brewguide-overview-component-js": () => import("./../../../src/pages/brewguide/brewguideOverviewComponent.js" /* webpackChunkName: "component---src-pages-brewguide-brewguide-overview-component-js" */),
  "component---src-pages-brewguide-filter-js": () => import("./../../../src/pages/brewguide/filter.js" /* webpackChunkName: "component---src-pages-brewguide-filter-js" */),
  "component---src-pages-brewguide-frenchpress-js": () => import("./../../../src/pages/brewguide/frenchpress.js" /* webpackChunkName: "component---src-pages-brewguide-frenchpress-js" */),
  "component---src-pages-brewguide-index-js": () => import("./../../../src/pages/brewguide/index.js" /* webpackChunkName: "component---src-pages-brewguide-index-js" */),
  "component---src-pages-brewguide-siebtraeger-js": () => import("./../../../src/pages/brewguide/siebtraeger.js" /* webpackChunkName: "component---src-pages-brewguide-siebtraeger-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-purchaseconfirmation-js": () => import("./../../../src/pages/purchaseconfirmation.js" /* webpackChunkName: "component---src-pages-purchaseconfirmation-js" */),
  "component---src-pages-taste-js": () => import("./../../../src/pages/taste.js" /* webpackChunkName: "component---src-pages-taste-js" */),
  "component---src-pages-tnc-js": () => import("./../../../src/pages/tnc.js" /* webpackChunkName: "component---src-pages-tnc-js" */)
}

